import { FC, ReactNode } from 'react'
import Card from 'components/base/Card'
import Button from 'components/base/forms/Button'
import Link from 'next/link'
import cn from 'classnames'
import { sendOfferClick } from 'common/sendOfferClick' // we need this to make JSX compile

type TideCardType = {
  title: ReactNode
  logo: string
  link?: string
  name: string
  image: string
  id: string
  className?: string
  badge?: string
  style?: object
}

const FeaturedOfferCard: FC<TideCardType> = ({
  badge,
  className,
  id,
  image,
  link,
  logo,
  name,
  style,
  title,
}) => {
  return (
    <Card padding={0} className={cn(className, 'featured-offer-card')}>
      <div className='flex-row'>
        <div className='flex-fill p-5'>
          <Button
            size='xs'
            theme='lightOutlineThin'
            className='btn-tide mb-2 py-1 cursor-default'
          >
            {badge || 'Members'}
          </Button>
          <h3 className='mb-2 lh-1 text-semibold text-white mt-2'>
            <>{title}</>
          </h3>
          <div className={'text-left'}>
            <img src={logo} className={'ad-brand'} />
          </div>
          <div className='flex-row mt-4'>
            {link ? (
              <a
                href={link}
                className='btn btn-primary'
                onClick={() => {
                  sendOfferClick(name, document.location.pathname, true)
                }}
                target='_blank'
                rel='noreferrer'
              >
                Claim Offer
              </a>
            ) : (
              <Link href={`/marketplace/${id}/`}>
                <Button
                  onClick={() => {
                    sendOfferClick(name, document.location.pathname)
                  }}
                  className='px-4'
                >
                  Claim Offer
                </Button>
              </Link>
            )}
          </div>
        </div>
        <img className='character' src={image} />
      </div>
    </Card>
  )
}

export default FeaturedOfferCard
