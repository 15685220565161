import React, { FC, useState } from 'react'
import FeaturedOfferCard from './FeaturedOfferCard'
import CardCarousel from './CardCarousel'

type OffersCardType = {}

const OffersCard: FC<OffersCardType> = ({}) => {
  return (
    <>
      <h2 className='mb-3'>Our Partners</h2>
      <div className='row'>
        <div className='offer-cards col-xxl-4 col-xl- mb-4 col-lg-6 col-md-12'>
          <FeaturedOfferCard
            name='tide'
            image={'/images/tide-team.png'}
            logo={'/images/tide.svg'}
            badge='Banking'
            id={`efbbc8c9-e80d-4bc5-bdb3-1957fcb73f79`}
            link='https://www.tide.co/partners/hoxton-mix/'
            title={
              <>
                <span className='tide-pink'>£75 cashback</span> when you open a
                business bank account for free
              </>
            }
          />
        </div>
        <div className='offer-cards col-xxl-4 col-xl- mb-4 col-lg-6 col-md-12'>
          <FeaturedOfferCard
            name='xero'
            badge='Accounting'
            className={'xero'}
            image={'/images/xero-hero.svg'}
            logo={'/images/xero-logo.svg'}
            link='https://xero5440.partnerlinks.io/wicxhsjgfuiz-q1e71'
            title={
              <>
                <span className='tide-pink'>90% off for 6 months</span>{' '}
                Exclusively to Hoxton Mix customers
              </>
            }
          />
        </div>
      </div>
    </>
  )
}

export default OffersCard
